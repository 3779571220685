import validationMessages from 'vee-validate/dist/locale/fr';
import {fr as vuetifymessages} from "vuetify/lib/locale";

const fr = {
  $vuetify: {
    ...vuetifymessages,
    dataTable: {
      itemsPerPageText: "Éléments par page:",
    },
    dataFooter: {
      pageText: '{0}-{1} sur {2}',
      itemsPerPageAll: "Toute",
    }
  },
  validations: {
    ...validationMessages.messages,
    required: 'le champ {_field_} est obligatoire',
    max: "le champ {_field_} est trop",
    ext: "Format de fichier incorrect",
    ext1: "Seuls les fichiers .jpg, .jpeg, .png peuvent être téléchargés",
    confirmed: "Le mot de passe ne correspond pas",
    size: "La taille de l'image ne doit pas dépasser 1 Mo",
    image: "Le fichier doit être une image"
  },
  email: "Email",
  password: "Mot de passe",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmez le mot de passe",
  login: "login",
  password_forgot: "Mot de passe oublié?",
  warning: 'Avertissement!',
  are_you_sure: 'Es-tu sûr?',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  save: "Sauver",
  reset: "Réinitialiser",
  continue: "Continuer",
  back: "Dos",
  send: "Envoyer",
  edit: "Modifier",
  delete: "Supprimer",
  create: "Créer",
  completed: "Complété",
  ok: "OK",
  to_login: "Se connecter",
  are_you_sure_delete: "Êtes-vous sûr de bien vouloir supprimer cet élément?",
  new_user: "Nouvel utilisateur",
  edit_user: "Modifier l'utilisateur",
  user_info: "Informations utilisateur",
  badges: "Insignes",
  firstname: "Prénom",
  lastname: "Nom de famille",
  employeeid: 'ID employé',
  secondaryid: "ID secondaire",
  country : "Pays",
  role: "Rôle",
  roles: "Rôles",
  company: "Entreprise",
  division: "Division",
  location: "Emplacement",
  birthdate: "Date de naissance",
  hiredate: "Date d'embauche",
  isnewhire: "Est-ce une nouvelle recrue?",
  badgetype: 'Type: {0}',
  badgepoints: "Points: {0}",
  badgecoins: "Pièces: {0}",
  token_not_found: "Jeton introuvable",
  token_or_user_not_found: "Jeton ou utilisateur introuvable",
  unknown_error: "Erreur inconnue",
  image: "Image",
  dragndrop: "Faites glisser et déposez le fichier ici ou cliquez pour téléverser",
  send_password_reset: "ENVOYER UN EMAIL DE RÉINITIALISATION DU MOT DE PASSE",
  invalid_credentials: "les informations d'identification invalides",
  notifications: "Notifications",
  see_all: "Voir tout",
  sent_a_suggestion: "envoyé une suggestion.",
  return_to_admin: "Retour à l'administrateur",
  profile: "Profil",
  logout: "Se déconnecter",
  reset_data: "Réinitialiser les données",
  you_are_about_reset: "Vous êtes sur le point de réinitialiser les données. Voulez-vous continuer?",
  companies: "Entreprises",
  users: "Utilisateurs",
  divisions_locations: "Divisions/emplacements",
  divisions: "Divisions",
  locations: "Emplacements",
  leaderboard: "Classement",
  reports: "Rapports",
  transfer_coins: "Transférer des pièces",
  settings: "Paramètres",
  programs: "Programmes",
  home_locations: "Emplacements d'origine",
  gps: "Données du système GPS",
  quizzes: "Quiz",
  data_reset_successfully: "Réinitialisation des données avec succès",
  company_ids: "ID d'entreprise",
  role_ids: "ID de rôle",
  exclusion_user_id_list: "Liste d'ID utilisateur d'exclusion",
  inclusion_user_id_list: "Liste d'ID utilisateur d'inclusion",
  upload_users_to_awardco: "Télécharger des utilisateurs sur le Awardco",
  confirmation: "Confirmation",
  you_are_about_save_data: "Vous êtes sur le point de sauvegarder ces données",
  companies_id: "ID de l'entreprise",
  roles_id: "ID de rôle",
  exclusion_user_id: "ID utilisateur exclu",
  inclusion_user_id: "ID utilisateur d'inclusion",
  are_you_sure_upload_to_awardco: "Êtes-vous sûr de vouloir télécharger des utilisateurs sur le Awardco?",
  at_least_one_company_required: "Au moins un identifiant d'entreprise est requis",
  at_least_one_role_required: "Au moins un identifiant de rôle est requis",
  something_went_wrong: "Quelque chose s'est mal passé",
  users_are_uploaded_to_awardco: "Les utilisateurs sont téléchargés sur le Awardco",
  new_badge: "Nouvel insigne",
  edit_badge: "Modifier le insigne",
  additional_settings: "Paramètres additionnels",
  choose_from_library: "Choisissez dans la bibliothèque",
  badge_name: "Nom du insigne",
  points: "Points",
  coins: "Pièces",
  coin: "Pièce",
  description: "Description",
  feed_type: "Type d'alimentation",
  allow_on_recognition: "Autoriser le flux de reconnaissance",
  monthly_limit_per_division_is_enabled: "La limite mensuelle par division est activée",
  monthly_division_limit: "Limite mensuelle/division",
  monthly_limit_per_driver_is_enabled: "La limite mensuelle par conducteur est activée",
  select_all: 'tout sélectionner',
  monthly_driver_limit: "Limite mensuelle/conducteur",
  monthly_limit_per_driver: "Limite mensuelle par conducteur",
  monthly_limit_per_division: "Limite mensuelle par division",
  select_icon: "Sélectionner l'icône",
  default: "Défaut",
  none: "Aucun",
  global: "Mondial",
  within_division: "Au sein de la division",
  within_home_location: "À l'intérieur du domicile",
  assign_badges: "Attribuer des insignes",
  badges_data: "Données des insignes",
  users_data: "Données des utilisateurs",
  info: "Info",
  reached_monthly_limit: "Limite mensuelle atteinte",
  search: "Chercher",
  comment: "Commentaire",
  badge: "Insigne",
  date: "Date",
  no_comment: "Aucun commentaire",
  assign: "Attribuer",
  assign_without_comment: "Attribuer un ou des insignes sans commentaire?",
  unable_to_fetch_icon: "Impossible de récupérer l'icône",
  badge_assigned_successfully: "Insigne affecté avec succès",
  all_locations: "Tous les emplacements",
  new_company: "Nouvelle l'entreprise",
  company_name: "Nom de l'entreprise",
  company_key: "Clé de l'entreprise",
  driver_limit: "Limite de conducteur",
  non_driver_limit: "Limite de non-conducteur",
  show_awardco_link_in_app: "Afficher le lien Awardco dans l'application",
  allow_user_point_redemption: "Autoriser l'échange de points utilisateur",
  suggestion_box: "Boîte à suggestion",
  show_suggestion_box: "Afficher la boîte à suggestions",
  user_import_type: "Importation d'utilisateurs",
  show_badges_feed: "Afficher le flux de badges",
  late_buffer: "Tampon de retard",
  late_buffer_min: "Tampon de retard (minutes)",
  new_hire_buffer_is_enabled: "Le tampon de nouvelle embauche est activé",
  new_hire: "Nouvelle embauche",
  new_hire_buffer_days: "Nouvelle embauche (jours)",
  enable_managed_controller_transfer: "Activer le transfert contrôlé géré",
  name: "Nom",
  driver_count: "Nombre de conducteurs",
  non_driver_count: "Nombre de non-conducteurs",
  date_created: "Date créée",
  actions: "Actions",
  edit_company: "Modifier l'entreprise",
  transfer_coins_to_redeem_partner: "Transférer des pièces pour échanger un partenaire",
  transfer_queue: "File d'attente de transfert",
  active: "Actif",
  inactive: "Inactif",
  deleted: "Supprimé",
  batch_user_import: "Importation par lots",
  upload_users: "Télécharger des utilisateurs",
  expected_csv: "Format de ligne CSV attendu",
  csv_headers: "EmployeeNo,DriverFirstName,DriverLastName,Email,HomeLocationName,HireDate,BirthDate,SecondaryId",
  file: "Fichier",
  sheduled_invitation: "Invitation programmée",
  campaign_name: "Nom de la campagne",
  scheduled_at: "Prévu à",
  select_datetime: "Sélectionnez Dateheure",
  template_id: "Identifiant du modèle",
  upload: "Télécharger",
  make_active: "Rendre actif",
  make_inactive: "Rendre inactif",
  restore: "Restaurer",
  permanent_delete: "Suppression définitive",
  oops: "OOPS",
  restore_user: "Restaurer l'utilisateur?",
  delete_user_permanently: "Supprimer définitivement l'utilisateur?",
  are_you_sure_delete_user: "Êtes-vous sûr de vouloir supprimer cet utilisateur?",
  are_you_sure_change_status: "Êtes-vous sûr de vouloir modifier le statut de cet utilisateur?",
  loginas_error: "Il semble que cet utilisateur n'ait pas terminé son autorisation ou\n processus de réinitialisation du mot de passe et est inactif pour le moment.",
  newhire: "Nouvelle embauche",
  all: "Tout",
  status_changed: "Statut modifié",
  super_admin: "Super administrateur",
  company_admin: "Administrateur de l'entreprise",
  manager: "Directeur",
  supervisor: "Superviseur",
  non_driver_user: "Utilisateur non-pilote",
  status: "Statut",
  date_range: "Plage de dates",
  transfer_current: "Courant de transfert",
  transfer_by_date: "Transfert par date",
  amount: "Montant",
  redeem: "Racheter",
  confirm_transfer: "Confirmer le transfert",
  select_transfer_date: "Sélectionnez la date du transfert",
  fullname: "Nom et prénom",
  current_coins: "Les pièces actuelles",
  coins_by_date_range: "Pièces par plage de dates",
  pending_transfer: "En attente de transfert",
  transfer_amount: "Montant du transfert (pièces complètes uniquement)",
  alltime: "Tout le temps",
  new_division: "Nouvelle division",
  edit_division: "Modifier la division",
  division_data: "Données des divisions",
  division_badges: "Insignes de division",
  division_settings: "Paramètres de division",
  division_name: "Nom de la division",
  division_has_leaderboard: "La division a un classement",
  division_has_gps_data_page: "La division a une page de données GPS",
  default_badges: "Insignes par défaut",
  division_deleted: "Division supprimée",
  change_password: "Changer le mot de passe",
  old_password: "Ancien mot de passe",
  image_successfully_updated: "Image mise à jour avec succès",
  password_updated: "Mot de passe mis à jour",
  suggestion: "Suggestion",
  mark_as_read: "Marquer comme lu",
  are_you_sure_mark_as_completed: "Êtes-vous sûr de vouloir marquer cette suggestion comme terminée?",
  success: "Succès",
  employee_score_card: "Carte de pointage des EMPLOYÉS",
  score: "Score",
  performance_history: "Historique des performances",
  complete_badge_history: "Historique complet des insignes",
  week: "Semaine",
  month: "Mois",
  year: "Année",
  export_data: "Exporter des données",
  earned: "Gagné",
  average: "Moyenne",
  median: "Médian",
  mode: "Mode",
  low: "Faible",
  high: "Haut",
  period: "Période",
  ranking: "Classement",
  january: "Janvier",
  february: "Février",
  march: "Mars",
  april: "Avril",
  may: "Peut",
  june: "Juin",
  july: "Juillet",
  august: "Août",
  september: "Septembre",
  october: "Octobre",
  november: "Novembre",
  december: "Décembre",
  rewardedby: "Récompensé par",
  awardedby: "Attribué par",
  prev_three_months: "3 mois précédents",
  prev_sixst_months: "6 mois précédents",
  prev_twelw_months: "12 mois précédents",
  remark: "remarque",
  upload_file: "Téléverser un fichier",
  sheduled_time_file: "Fichier horaire programmé",
  time_card_file: "Fichier de fiche de pointage",
  report_shedule_access_file: "Fichier d'accès au calendrier du rapport",
  import_results: "Importer les résultats",
  trips_upload_successfully: "Voyages téléchargés avec succès",
  acknowledged: "Reconnue",
  released_status: "Released Status",
  released_by: "Statut de sortie",
  released: "Libéré",
  pending: "En attente",
  cant_find_import_system: "Impossible de trouver le système d'importation",
  delete_gps_title: "Vous êtes sur le point de supprimer définitivement toutes les données téléchargées pour la période {0}. Es-tu sûr?",
  data_successfully_deleted: "Les données sont supprimées avec succès",
  show_board_data: "Afficher les données du tableau",
  driver: "Conducteur",
  badge_count: "Nombre de insignes",
  no_data_available: "PAS DE DONNÉES DISPONIBLES",
  new_location: "Nouvel emplacement",
  edit_location: "Modifier l'emplacement",
  address: "Adresse",
  city: "Ville",
  state: "État",
  zipcode: "Code postal",
  location_successfully_updated: "Emplacement mis à jour avec succès",
  location_successfully_created: "Emplacement créé avec succès",
  location_successfully_deleted: "Emplacement supprimé avec succès",
  trip_data_date_range: "Plage de dates des données de voyage",
  note_time_format: "Note! Nous utilisons le format d'heure 24h",
  color_legend: "Légende des couleurs",
  no_perfect_attendance: "Pas de badge de présence parfait",
  late_arrival: "Arrivée tardive",
  no_show: "Pas de présentation",
  error_data: "Champs obligatoires/Doublons/Heure planifiée supérieure à WorkDate/Déconnexion réelle avant la connexion réelle",
  day: "Jour",
  time: "Temps",
  number_of_trips: "Nombre de voyages",
  add_new_item: "Ajoute un nouvel objet",
  save_for_later: "Garder pour plus tard",
  release_data: "Données de publication",
  are_you_sure_restore: "Etes-vous sûr de vouloir restaurer ceci ?",
  release_warning: "Avertissement : Vous êtes sur le point de publier des données pour {0}, souhaitez-vous continuer ?",
  driver_name: "Nom du conducteur",
  employee_id: "ID d'employé",
  truck: "Camion",
  workdate: "Date de travail",
  scheduled_time_in: "Heure prévue dans",
  actual_login: "Connexion réelle",
  actual_logout: "Déconnexion réelle",
  num_of_trips: "№ de voyages",
  trip_deleted: "Voyage supprimé",
  trip_data_restored: "Données de voyage restaurées",
  trip_updated: "Voyage mis à jour",
  monday: "lundi",
  tuesday: "mardi",
  wednesday: "mercredi",
  thursday: "jeudi",
  friday: "vendredi",
  saturday: "samedi",
  sunday: "dimanche",
  restart_queue: "Redémarrer la file d'attente",
  remove_user_from_queue: "Supprimer l'utilisateur de la file d'attente?",
  employee_name: "Nom de l'employé",
  transfer_am: "Montant du transfert",
  scheduled_date: "Date prévue",
  processed_date: "Date de traitement",
  global_user_report: "Rapport utilisateur global",
  load_next_rows: "Charger les 50 lignes suivantes",
  show_data: "Afficher les données",
  terms_of_use: "Conditions d'utilisation",
  privacy_policy: "POLITIQUE DE CONFIDENTIALITÉ",
  eula: "CLUF",
  lang: "Langue",
  notifications_center: "Centre de notifications (bêta)",
  notification: "Notification",
  notification_title: "Titre de la notification",
  notification_text: "Texte de la notification",
  new_notification: "Nouvelle notification",
  send_notification: "Envoyer une notification",
  select_recipients: "Sélectionner les destinataires",
  general_data: "Informations générales",
  company_settings: "Paramètres des entreprises",
  email_settings: "Paramètres de l'e-mail",
  please_leave_empty: " Veuillez laisser les champs vides si vous souhaitez utiliser notre système de courrier électronique par défaut.",
  company_admin_template_id: "ID du modèle de l'administrateur de l'entreprise",
  manager_template_id: "ID du modèle de gestionnaire",
  supervisor_template_id: "ID du modèle de superviseur",
  driver_template_id: "ID du modèle de conducteur",
  reset_password_template_id: "Réinitialiser le mot de passe du modèle ID",
  reset_password_mobile_template_id: "Réinitialiser le mot de passe (mobile) modèle ID",
  reset_password_email_is_sent: "L'email de réinitialisation du mot de passe est envoyé",
  are_you_sure_request_password_reset: "Êtes-vous sûr de vouloir réinitialiser votre mot de passe?",
  are_you_sure_password_change: "Êtes-vous sûr de vouloir changer de mot de passe?",
  request_password_reset: "Réinitialiser le mot de passe",
  password_changed: "Mot de passe modifié",
  queueStatuses : {
    cancelled: "Annulé",
    error: "Erreur",
    pending: "En attente",
    success: "Succès"
  },
  show_code: "Afficher le code de vérification",
  code: "Code de vérification",
  notification_created: "Notification créée",
  notification_results: "Résultats de la notification",
  notification_succcessfull: "Le message pour {0} a été envoyé avec succès",
  notification_unsuccessfull: "Le message pour {0} n'a pas été envoyé avec succès",
  send_notification_to_user: "Envoyer une notification à l'utilisateur",
  notifications_history: "Historique des notifications",
  message_id: "Identifiant du message",
  message_text: "Texte du message",
  recipient: "Destinataire",
  created_date: "Date de création",
  delivered: "Délivré",
  not_delivered: "Non livrée",
  created_by: "Créé par",
  total_transfer_amount: "Montant total du transfert",
  id: "ID",
};

export default fr
