<script>

import {apiCall} from "@/utils/api";
import Informationdialog from "@/components/InformationDialog.vue";

export  default {
  name: "Notificationshistory",
  components: {Informationdialog},

  data(context) {
    return {
      searchValue: null,
      search: null,
      informationdialogVisible: false,
      informationdialogText: "",
      footerProps: {
        "items-per-page-options": [10, 20, 50],
      },
      loading: false,
      headers: [
        {text: 'ID', value: "id", sortable: false},
        {text: context.$t('message_text'), value: "messagetext", sortable: false},
        {text: context.$t('recipient'), value: "recipient", sortable: false},
        {text: context.$t('status'), value: "delivered", sortable: false},
        {text: context.$t('created_date'), value: "createddate", sortable: false},
        {text: context.$t('created_by'), value: "createdby", sortable: false},
      ],
      notifications: [],
      page: 1,
      totalElems: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [true]
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true;

      const params = {
        page: this.options.page - 1,
        sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
        sortField: this.options.sortBy[0] || "id",
        perpage: this.options.itemsPerPage,
        search: this.searchValue,
      };

      apiCall(
          "get",
          "notifications/load",
          {
            params: params,
          },
          true
      ).then((response) => {

        this.notifications = response.data.result.content;

        this.totalElems = response.data.result.totalElements;
        this.loading = false;
      });

    },
    showMessage(text) {
      this.informationdialogText = text;
      this.informationdialogVisible = true;
    },
    setSearch() {
      this.searchValue = this.search;
      this.options = {...this.options, page: 1}
    },
    clearSearch() {
      this.search = null;
      this.searchValue = this.search;

      this.$nextTick(() => {
        this.options = {...this.options, page: 1}
      });
    },
  }
}



</script>
<template>
  <v-layout>
    <div class="col-12">
      <v-data-table
          mobile-breakpoint="980"
          :headers="headers"
          :items="notifications"
          :server-items-length="totalElems"
          :loading="loading"
          :footer-props="footerProps"
          :page="page"
          :options.sync="options"
          class="users-table"
          must-sort
      >
        <template v-slot:top>
          <v-toolbar class="flex-columned-toolbar" height="auto" flat>
            <v-col class="py-4 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-2 col-12">
              <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  hide-details
                  clearable
                  @keyup.enter.prevent="setSearch()"
                  @click:clear="clearSearch()"
              ></v-text-field>
            </v-col>

          </v-toolbar>
        </template>
        <template v-slot:item.messagetext="{ item }">
          <div>
            <template v-if="item.messagetext.length<= 15">
              <div>{{item.messagetext}}</div>
            </template>
            <template v-else>
              <div>
                <v-btn color="secondary" dark @click="showMessage(item.messagetext)">
                  Show message
                </v-btn>
              </div>
            </template>
          </div>
        </template>
        <template v-slot:item.delivered="{ item }">
          <div> {{ item.delivered ? $t("delivered") : $t("not_delivered")  }}</div>
        </template>
      </v-data-table>
      <Informationdialog
          :title="$t('message_text')"
          :text="informationdialogText"
          :button-text="$t('ok')"
          :visible="informationdialogVisible"
          @informationdialog:buttonClick="informationdialogVisible = !informationdialogVisible"
      />
    </div>
  </v-layout>
</template>