<template>
    <v-layout>
        <div class="col-12">
            <v-data-table
                    mobile-breakpoint="920"
                    :footer-props="footerProps"
                    :headers="headers"
                    :items="locations"
                    :loading="loading"
                    :options.sync="options"
                    :server-items-length="totalLocations"
                    class="elevation-0 locations-table"
            >
                <template v-slot:top>
                    <v-toolbar class="flex-columned-toolbar" height="auto" flat>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 my-2 col-12">
                            <v-select
                                    :items="allItems(companies)"
                                    @change="selectCompany"
                                    hide-details
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('company')"
                                    v-model="filteredcompany"
                            >
                            </v-select>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 my-2 col-12">
                            <v-select
                                    :disabled="filteredcompany == 0"
                                    :items="allItems(divisions)"
                                    @change="selectDivision"
                                    hide-details
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('division')"
                                    v-model="filteredDivision"
                            >
                            </v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-dialog max-width="500px" v-model="dialog">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        class="mb-2"
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    {{ $t('new_location') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <validation-observer ref="observer" v-slot="{ invalid }">
                                    <v-form @submit.prevent="save">
                                        <v-card-title class="dialog-title">
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('name')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    :label="$t('name')"
                                                                    v-model="editedItem.name"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('country')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-select
                                                                    :error-messages="errors"
                                                                    :items="countries"
                                                                    :label="$t('country')"
                                                                    v-model="editedItem.country"
                                                            ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('address')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    :label="$t('address')"
                                                                    v-model="editedItem.address"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('city')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    :label="$t('city')"
                                                                    v-model="editedItem.city"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('state')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    :label="$t('state')"
                                                                    v-model="editedItem.state"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('zipcode')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-text-field
                                                                    :error-messages="errors"
                                                                    :label="$t('zipcode')"
                                                                    v-model="editedItem.zipcode"
                                                            ></v-text-field>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12" v-if="editedIndex === -1">
                                                        <validation-provider
                                                                :name="$t('company')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-select
                                                                    :error-messages="errors"
                                                                    :items="companies"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    :label="$t('company')"
                                                                    v-model="selectedCompany"
                                                            ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                    <v-col class="pa-0" cols="12">
                                                        <validation-provider
                                                                :name="$t('division')"
                                                                rules="required"
                                                                v-slot="{ errors }"
                                                        >
                                                            <v-select
                                                                    :error-messages="errors"
                                                                    :items="formDivisions"
                                                                    item-text="name"
                                                                    item-value="id"
                                                                    :label="$t('division')"
                                                                    multiple
                                                                    v-model="selectedDivisions"
                                                            ></v-select>
                                                        </validation-provider>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn @click="close" class="cancel-button px-3" text>
                                                {{ $t('cancel') }}
                                            </v-btn>
                                            <v-btn
                                                    :class="{
                          'confirm-button px-5': !invalid,
                          'disabled px-5': invalid,
                        }"
                                                    :disabled="invalid"
                                                    :loading="inuse"
                                                    text
                                                    type="submit"
                                            >
                                                {{ $t('save') }}
                                            </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </validation-observer>
                            </v-card>
                        </v-dialog>
                        <Confirmationdialog
                                :visible="dialogDelete"
                                @confirmationdialog:cancelClick="closeDelete"
                                @confirmationdialog:confirmClick="deleteItemConfirm"
                        >
                            <template v-slot:cardText>{{ $t('are_you_sure_delete') }}</template>
                        </Confirmationdialog>
                     </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon @click="editItem(item)" class="mr-2" size="19">
                        mdi-pencil
                    </v-icon>
                    <v-icon @click="deleteItem(item)" size="19"> mdi-delete</v-icon>
                </template>
            </v-data-table>
        </div>
    </v-layout>
</template>
<script>
    import {apiCall} from "@/utils/api.js";
    import {mapActions} from "vuex";
    import {ValidationObserver, ValidationProvider } from "vee-validate";
    import Confirmationdialog from "@/components/ConfirmationDialog";



    export default {
        name: "SALocations",
        components: {
            ValidationObserver,
            ValidationProvider,
            Confirmationdialog
        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                inuse: false,
                dialog: false,
                dialogDelete: false,
                totalLocations: 0,
                locations: [],
                divisions: [],
                companies: [],
                countries: [],
                formDivisions: [],
                loading: true,
                options: {},
                selectedDivisions: [],
                filteredcompany: 0,
                filteredDivision: 0,
                selectedCompany: 0,
                page: 1,
                perpage: 10,
                headers: [
                    {text: context.$t('name'), value: "name", sortable: false},
                    {text: context.$t('address'), value: "address", sortable: false},
                    {text: context.$t('city'), value: "city", sortable: false},
                    {text: context.$t('state'), value: "state", sortable: false},
                    {text: context.$t('division'), value: "divisionnames", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    name: "",
                    country: "",
                    address: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    divisionsid: [],
                },
                defaultItem: {
                    id: -1,
                    name: "",
                    country: "",
                    address: "",
                    city: "",
                    state: "",
                    zipcide: "",
                    divisionsid: [],
                },
            };
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('new_location') : this.$t('edit_location');
            },
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            async selectedCompany(newVal) {
                await this.getFormDivisions();
                if (newVal != 0 && this.editedItem.id === -1) {
                    this.selectedDivision = 0;
                    this.selectedDivisions = [];
                }
            },
        },
        async created() {
            // await this.getCompanies();
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            getFormDivisions() {
                apiCall("get", `divisions/get?companyid=${this.selectedCompany}`, true).then((response) => {
                    this.formDivisions = response.data;
                });
            },
            selectCompany() {
                this.options = {...this.options, page: 1}
                this.getDivisions();
                this.filteredDivision = 0;
            },
            selectDivision() {

                this.options = {...this.options, page: 1}
            },
            allItems(items) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: this.$t('all'),
                    },
                    ...items,
                ];
                return arr;
            },
            async getCompanies() {
                await apiCall("get", "companies/get", true).then((response) => {
                    this.companies = response.data;
                });
            },
            getDivisions() {
                apiCall("get", `divisions/get?companyid=${this.filteredcompany}`, true).then((response) => {
                    this.divisions = response.data;
                });
            },
            async getDataFromApi() {
                this.filteredcompany == 0 ? await this.getCompanies() : "";

                this.loading = true;

                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "id",
                    perpage: this.options.itemsPerPage,
                    companyid: this.filteredcompany,
                    divisionid: this.filteredDivision,
                };

                apiCall(
                    "get",
                    "locations",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.locations = response.data.locations.content;
                    this.totalLocations = response.data.locations.totalElements;
                    this.countries = response.data.countryList;
                    this.loading = false;
                });
            },
            updateLocation() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "locations/update",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status
                                ? this.$t('location_successfully_updated')
                                : response.data.result,
                            toggle: true,
                        });

                        this.getDataFromApi();
                    } else {

                        if (response.data) {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    }
                })
                    .catch(() => {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    }).finally(() => {
                    this.inuse = false;
                    this.loading = false;
                });
            },
            createLocation() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "locations/create",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status
                                ? this.$t('location_successfully_created')
                                : response.data.result,
                            toggle: true,
                        });

                        this.getDataFromApi();
                    } else {

                        if (response.data) {
                            this.setSnackbar({
                                color: "error",
                                text: this.$t('unknown_error'),
                                toggle: true,
                            });
                        }
                    }
                })
                    .catch(() => {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        })
                    }).finally(() => {
                    this.inuse = false;
                    this.loading = false;
                });

            },
            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem.divisionsid = item.divisionsid;
                this.selectedCompany = item.companyid;
                this.selectedDivisions = item.divisionsid;
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },

            deleteItemConfirm() {
                // api remove
                this.deleteLocation();
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.selectedDivisions = [];
                    this.selectedCompany = 0;
                });
                this.$refs.observer.reset();
            },

            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            deleteLocation() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "locations/delete",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    if (response.data) {
                        this.setSnackbar({
                            color: response.data.status ? "success" : "error",
                            text: response.data.status
                                ? this.$t('location_successfully_deleted')
                                : response.data.result,
                            toggle: true,
                        });

                        this.getDataFromApi();
                    } else {
                        this.setSnackbar({
                            color: "error",
                            text: this.$t('unknown_error'),
                            toggle: true,
                        });
                    }
                }).catch(() => {
                    this.setSnackbar({
                        color: "error",
                        text: this.$t('unknown_error'),
                        toggle: true,
                    });
                }).finally(() => {
                    this.inuse = false;
                    this.loading = false;
                });
            },
            save() {
                if (this.editedIndex > -1) {
                    // edit
                    this.editedItem.id = this.editedIndex;
                    this.defaultItem.id = this.editedIndex;
                    this.editedItem.divisionsid = this.selectedDivisions;
                    this.updateLocation();
                } else {
                    // create
                    this.editedItem.divisionsid = this.selectedDivisions;
                    this.createLocation();
                }
                this.close();
            },
        },
    };
</script>
<style scoped>
    @media (max-width: 1280px) {
        .flex-columned-toolbar >>> .v-toolbar__content {
            flex-wrap: wrap;
            justify-content: right;
        }

    }
    @media (max-width: 480px) {
        .locations-table /deep/ .v-data-footer{
            justify-content: center;
            padding-bottom: 8px;
        }
        .locations-table /deep/ .v-data-footer__pagination{
            display: none;
        }
        .locations-table /deep/ .v-data-footer__select .v-input{
            margin: 0 10px !important;
        }
    }
</style>
