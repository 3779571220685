<template>
    <v-layout>
        <div class="col-12 elevation-0">
            <v-data-table
                    mobile-breakpoint="920"
                    :footer-props="footerProps"
                    :headers="headers"
                    :items="divisions"
                    :loading="loading"
                    :options.sync="options"
                    :page="page"
                    :server-items-length="totalDivisions"
                    class="elevation-0 divisions-table"
            >
                <template v-slot:top>
                    <v-toolbar class="flex-columned-toolbar" flat height="auto">
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 my-2 col-12">
                            <v-text-field
                                    @click:clear="clearSearch()"
                                    @keyup.enter.prevent="setSearch()"
                                    clearable
                                    hide-details
                                    :label="$t('search')"
                                    prepend-icon="mdi-magnify"
                                    single-line
                                    v-model="search"
                            ></v-text-field>
                        </v-col>
                        <v-col class="py-4 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 my-2 col-12">
                            <v-select
                                    :items="allcompanies(companies)"
                                    @change="selectCompany"
                                    hide-details
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('company')"
                                    v-model="filteredcompany"
                            >
                            </v-select>
                        </v-col>
                      <v-spacer></v-spacer>
                        <v-dialog max-width="800px" v-model="dialog">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    {{ $t('new_division') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <template v-if="editedIndex === -1">
                                    <v-stepper v-model="step">
                                        <v-stepper-header>
                                            <v-stepper-step :complete="step > 1" step="1">
                                                {{ $t('division_data') }}
                                            </v-stepper-step>
                                            <v-divider></v-divider>
                                            <v-stepper-step :complete="step > 2" step="2">
                                                {{ $t('division_badges') }}
                                            </v-stepper-step>
                                            <v-divider></v-divider>
                                            <v-stepper-step :complete="step > 3" step="3">
                                                {{ $t('division_settings') }}
                                            </v-stepper-step>
                                        </v-stepper-header>

                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <validation-observer ref="observer" v-slot="{ invalid }">
                                                    <v-form @submit.prevent="step = 2">
                                                        <v-card-title>
                                                            <span class="headline">{{ formTitle }}</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col class="pa-0" cols="12">
                                                                        <validation-provider
                                                                                :name="$t('division_name')"
                                                                                rules="required"
                                                                                v-slot="{ errors }"
                                                                        >
                                                                            <v-text-field
                                                                                    :error-messages="errors"
                                                                                    :label="$t('division_name')"
                                                                                    v-model.trim="editedItem.name"
                                                                            ></v-text-field>
                                                                        </validation-provider>
                                                                    </v-col>
                                                                    <v-col
                                                                            class="pa-0"
                                                                            cols="12"
                                                                            v-if="editedIndex === -1"
                                                                    >
                                                                        <validation-provider
                                                                                :name="$t('company')"
                                                                                rules="required|min_value:1"
                                                                                v-slot="{ errors }"
                                                                        >
                                                                            <v-select
                                                                                    :error-messages="errors"
                                                                                    :items="companies"
                                                                                    item-text="name"
                                                                                    item-value="id"
                                                                                    :label="$t('company')"
                                                                                    v-model="selectedCompany"
                                                                            ></v-select>
                                                                        </validation-provider>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn @click="close" class="cancel-button px-3" text>
                                                                {{ $t('cancel') }}
                                                            </v-btn>
                                                            <v-btn
                                                                    :disabled="invalid"
                                                                    class="confirm-button px-5"
                                                                    text
                                                                    type="submit"
                                                            >
                                                                {{ $t('continue') }}
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-form>
                                                </validation-observer>
                                            </v-stepper-content>
                                            <v-stepper-content step="2">
                                                <v-card-text class="overflow-y-auto"
                                                             style="min-height: 80%; max-height: 640px">
                                                    <v-list two-line>
                                                        <v-list-item-group
                                                                active-class="green--text"
                                                                multiple
                                                                v-model="selectedBadges"
                                                        >
                                                            <template v-for="(item, index) in badges">
                                                                <v-list-item :key="item.name" :value="item.id">
                                                                    <!-- eslint-disable-next-line -->
                                                                    <template v-slot:default="{ active }">
                                                                        <v-list-item-avatar>
                                                                            <v-img :src="item.smallimage"></v-img>
                                                                        </v-list-item-avatar>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title
                                                                                    v-text="item.name"
                                                                            ></v-list-item-title>
                                                                            <v-list-item-subtitle
                                                                                    class="text--primary"
                                                                                    :v-text="$t('badgetype', [item.type])"
                                                                            ></v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                    :v-text="$t('badgepoints', [item.points])"
                                                                            ></v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                    :v-text="$t('badgecoins', [item.coins.toFixed(2)])"
                                                                            ></v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                    </template>
                                                                </v-list-item>
                                                                <v-divider
                                                                        :key="index"
                                                                        v-if="index < badges.length - 1"
                                                                ></v-divider>
                                                            </template>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn @click="step = 1" color="blue darken-1" text
                                                    >{{ $t('back') }}
                                                    </v-btn
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="close" class="cancel-button px-3" text>
                                                        {{ $t('cancel') }}
                                                    </v-btn>
                                                    <v-btn
                                                            :disabled="selectedBadges.length == 0"
                                                            :loading="inuse"
                                                            @click="step = 3"
                                                            class="confirm-button px-5"
                                                            text
                                                    >
                                                        {{ $t('continue') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-stepper-content>
                                            <v-stepper-content step="3">
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-checkbox
                                                                        class="mt-0"
                                                                        hide-details
                                                                        v-model="editedItem.hasLeaderboard"
                                                                        :label="$t('division_has_leaderboard')"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-checkbox
                                                                        class="mt-0"
                                                                        hide-details
                                                                        v-model="editedItem.hasGpsDataPage"
                                                                        :label="$t('division_has_gps_data_page')"
                                                                ></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn @click="step = 2" color="blue darken-1" text
                                                    > {{ $t('back')}}
                                                    </v-btn
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="close" class="cancel-button px-3" text>
                                                        {{ $t('cancel') }}
                                                    </v-btn>
                                                    <v-btn
                                                            :loading="inuse"
                                                            @click="save()"
                                                            class="confirm-button px-5"
                                                            text
                                                    >
                                                        {{ $t('create') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                </template>
                                <template v-else>
                                    <v-stepper v-model="step">
                                        <v-stepper-header>
                                            <v-stepper-step :complete="step > 1" step="1">
                                                {{ $t('division_data') }}
                                            </v-stepper-step>
                                            <v-divider></v-divider>
                                            <v-stepper-step :complete="step > 2" step="2">
                                                {{ $t('division_badges') }}
                                            </v-stepper-step>
                                            <v-divider></v-divider>
                                            <v-stepper-step :complete="step > 3" step="3">
                                                {{ $t('division_settings') }}
                                            </v-stepper-step>
                                        </v-stepper-header>
                                        <v-stepper-items>
                                            <v-stepper-content step="1">
                                                <validation-observer ref="observer" v-slot="{ invalid }">
                                                    <v-form @submit.prevent="step = 2">
                                                        <v-card-title>
                                                            <span class="headline">{{ formTitle }}</span>
                                                        </v-card-title>

                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col class="pa-0" cols="12">
                                                                        <validation-provider
                                                                                :name="$t('division_name')"
                                                                                rules="required"
                                                                                v-slot="{ errors }"
                                                                        >
                                                                            <v-text-field
                                                                                    :error-messages="errors"
                                                                                    :label="$t('division_name')"
                                                                                    v-model.trim="editedItem.name"
                                                                            ></v-text-field>
                                                                        </validation-provider>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn @click="close" class="cancel-button px-3" text>
                                                                {{ $t('cancel') }}
                                                            </v-btn>
                                                            <v-btn
                                                                    :disabled="invalid"
                                                                    class="confirm-button px-5"
                                                                    text
                                                                    type="submit"
                                                            >
                                                                {{ $t('continue') }}
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-form>
                                                </validation-observer>
                                            </v-stepper-content>

                                            <v-stepper-content step="2">
                                                <v-card-text class="overflow-y-auto"
                                                             style="min-height: 80%; max-height: 640px">
                                                    <v-list two-line>
                                                        <v-list-group
                                                                active-class="green--text"
                                                                multiple
                                                        >
                                                            <template v-slot:activator>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ $t('division_badges')}}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </template>
                                                            <template
                                                                    v-for="(item, index) in editedItem.badges"
                                                            >
                                                                <v-list-item
                                                                        :class="{'v-list-item--active' : isBadgeSelected(item.id)}"
                                                                        @click="addBadge(item)"
                                                                        :key="item.name"
                                                                        :value="item.id">
                                                                    <!-- eslint-disable-next-line -->
                                                                    <template v-slot:default="{ active }">
                                                                        <v-list-item-avatar>
                                                                            <v-img :src="item.smallimage"></v-img>
                                                                        </v-list-item-avatar>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title
                                                                                    v-text="item.name"
                                                                            ></v-list-item-title>
                                                                            <v-list-item-subtitle
                                                                                    class="text--primary"
                                                                                    :v-text="$t('badgetype', [item.type])"
                                                                            ></v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                    :v-text="$t('badgepoints', [item.points])"
                                                                            ></v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                    :v-text="$t('badgecoins', [item.coins.toFixed(2)])"
                                                                            ></v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                    </template>
                                                                </v-list-item>

                                                                <v-divider
                                                                        :key="index"
                                                                        v-if="index < badges.length - 1"
                                                                ></v-divider>
                                                            </template>
                                                        </v-list-group>
                                                        <v-list-group
                                                                active-class="green--text"
                                                                multiple
                                                        >
                                                            <template v-slot:activator>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ $t('default_badges') }}
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </template>
                                                            <template v-for="(item, index) in badges">
                                                                <v-list-item
                                                                        :class="{'v-list-item--active' : isDefaultBadgeSelected(item.id)}"
                                                                        @click="addDefaultBadge(item)"
                                                                        v-if="!isDefaultBadgeAlreadySelected(item.id)"
                                                                        :key="item.name" :value="item.id">
                                                                    <!-- eslint-disable-next-line -->
                                                                    <template v-slot:default="{ active }">
                                                                        <v-list-item-avatar>
                                                                            <v-img :src="item.smallimage"></v-img>
                                                                        </v-list-item-avatar>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title
                                                                                    v-text="item.name"
                                                                            ></v-list-item-title>
                                                                            <v-list-item-subtitle
                                                                                    class="text--primary"
                                                                                    :v-text="$t('badgetype', [item.type])"
                                                                            ></v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                    :v-text="$t('badgepoints', [item.points])"
                                                                            ></v-list-item-subtitle>
                                                                            <v-list-item-subtitle
                                                                                    :v-text="$t('badgecoins', [item.coins.toFixed(2)])"
                                                                            ></v-list-item-subtitle>
                                                                        </v-list-item-content>
                                                                    </template>
                                                                </v-list-item>

                                                                <v-divider
                                                                        :key="index"
                                                                        v-if="index < badges.length - 1"
                                                                ></v-divider>
                                                            </template>
                                                        </v-list-group>
                                                    </v-list>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn color="blue darken-1" text @click="step = 1"
                                                    >{{ $t('back') }}
                                                    </v-btn
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="close" class="cancel-button px-3" text>
                                                        {{ $t('cancel') }}
                                                    </v-btn>
                                                    <v-btn
                                                            :disabled="selectedBadges.length == 0"
                                                            :loading="inuse"
                                                            @click="step = 3"
                                                            class="confirm-button px-5"
                                                            text
                                                    >
                                                        {{ $t('continue') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-stepper-content>
                                            <v-stepper-content step="3">
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-checkbox
                                                                        class="mt-0"
                                                                        hide-details
                                                                        v-model="editedItem.hasLeaderboard"
                                                                        :label="$t('division_has_leaderboard')"
                                                                ></v-checkbox>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-checkbox
                                                                        class="mt-0"
                                                                        hide-details
                                                                        v-model="editedItem.hasGpsDataPage"
                                                                        :label="$t('division_has_gps_data_page')"
                                                                ></v-checkbox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn @click="step = 2" color="blue darken-1" text
                                                    >{{$t('back')}}
                                                    </v-btn
                                                    >
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="close" class="cancel-button px-3" text>
                                                        {{$t('continue')}}
                                                    </v-btn>
                                                    <v-btn
                                                            :loading="inuse"
                                                            @click="save()"
                                                            class="confirm-button px-5"
                                                            text
                                                    >
                                                        {{ $t('save') }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-stepper-content>
                                        </v-stepper-items>
                                    </v-stepper>
                                </template>
                            </v-card>
                        </v-dialog>
                        <Confirmationdialog
                                :visible="dialogDelete"
                                @confirmationdialog:cancelClick="closeDelete"
                                @confirmationdialog:confirmClick="deleteItemConfirm"
                        >
                            <template v-slot:cardText>{{ $t('are_you_sure_delete') }}</template>
                        </Confirmationdialog>
                    </v-toolbar>
                </template>
                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                    <v-icon @click="editItem(item)" class="mr-2" size="19">
                        mdi-pencil
                    </v-icon>
                    <v-icon @click="deleteItem(item)" size="19"> mdi-delete</v-icon>
                </template>
            </v-data-table>
        </div>
    </v-layout>
</template>
<script>
    import {apiCall} from "@/utils/api.js";
    import {mapActions} from "vuex";
    import {ValidationObserver, ValidationProvider } from "vee-validate";
    import Confirmationdialog from "@/components/ConfirmationDialog";

    export default {
        name: "Divisions",
        components: {
            ValidationObserver,
            ValidationProvider,
            Confirmationdialog
        },
        data(context) {
            return {
                footerProps: {
                    "items-per-page-options": [10, 20, 50],
                },
                inuse: false,
                step: 1,
                dialog: false,
                dialogDelete: false,
                totalDivisions: 0,

                options: {
                    sortBy: ["name"],
                    sortDesc: [true]
                },
                companies: [],
                badges: [],
                selectedBadges: [],
                selectedNewBadges: [],
                divisions: [],
                selectedCompany: 0,
                loading: true,
                search: null,
                searchValue: null,
                page: 1,
                filteredcompany: 0,
                perpage: 10,
                headers: [
                    {
                      text: context.$t('id'),
                      align: "start",
                      sortable: false,
                      value: "id",
                    },
                    {
                        text: context.$t('name'),
                        align: "start",
                        sortable: true,
                        value: "name",
                    },
                    {
                        text: context.$t('company'),
                        sortable: false,
                        value: "companyname",
                    },
                    {text: context.$t('date_created'), value: "createddate", sortable: false},
                    {text: context.$t('actions'), value: "actions", sortable: false},
                ],
                editedIndex: -1,
                editedItem: {
                    id: 0,
                    name: "",
                    companyid: 0,
                    hasGpsDataPage: true,
                    hasLeaderboard: true
                },
                defaultItem: {
                    id: 0,
                    name: "",
                    companyid: 0,
                    hasGpsDataPage: true,
                    hasLeaderboard: true
                },
            };
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('new_division') : this.$t('edit_division');
            },
            firstStepDisabled() {
                return this.editedItem.name == "" || this.selectedCompany == 0;
            },
            activeBadges() {
                return this.selectedBadges.filter((elem) => elem.isselected === true);
            },
        },
        watch: {
            options: {
                handler() {
                    this.getDataFromApi();
                },
                deep: true,
            },
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        mounted() {
            // this.getDataFromApi();
            this.getCompanies();
            this.getDefaultBadges();
        },
        methods: {
            ...mapActions("service", ["setSnackbar"]),
            clearSearch() {
                this.search = null;
                this.searchValue = this.search;
                this.options = {...this.options, page: 1}
            },
            isBadgeSelected(id) {
                return this.selectedBadges.includes(id);
            },
            addBadge(item) {
                if (this.isBadgeSelected(item.id)) {
                    this.selectedBadges = [...this.selectedBadges.filter(el => el !== item.id)];
                } else {
                    this.selectedBadges = [...this.selectedBadges, item.id];
                }
            },
            isDefaultBadgeSelected(id) {
                return this.selectedNewBadges.includes(id);
            },
            addDefaultBadge(item) {
                if (this.isDefaultBadgeAlreadySelected(item.id)) {
                    return;
                }
                if (this.isDefaultBadgeSelected(item.id)) {
                    this.selectedNewBadges = [...this.selectedNewBadges.filter(el => el !== item.id)];
                } else {
                    this.selectedNewBadges = [...this.selectedNewBadges, item.id];
                }
            },
            allcompanies(companies) {
                let arr = [];
                arr = [
                    {
                        id: 0,
                        name: this.$t('all'),
                    },
                    ...companies,
                ];
                return arr;
            },
            selectCompany() {
                this.options = {...this.options, page: 1}
            },
            restoreData() {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.selectedCompany = 0;
                    this.selectedBadges = [];
                    this.selectedNewBadges = [];

                    this.step = 1;
                    // this.perpage = 10;
                    // this.page = 1;
                });
            },
            getDataFromApi() {
                this.loading = true;
                const params = {
                    page: this.options.page - 1,
                    sortDirection: this.options.sortDesc[0] == false ? "desc" : "asc",
                    sortField: this.options.sortBy[0] || "name",
                    perpage: this.options.itemsPerPage,
                    companyid: this.filteredcompany,
                    search: this.searchValue,
                };

                apiCall(
                    "get",
                    "divisions",
                    {
                        params: params,
                    },
                    true
                ).then((response) => {
                    this.divisions = [...response.data.content] || [];
                    this.totalDivisions = response.data.totalElements || 0;
                    this.loading = false;
                });
            },
            getDefaultBadges() {
                apiCall("get", "defaultbadges", true).then((response) => {
                    this.badges = response.data;
                    this.loading = false;
                });
            },
            getCompanies() {
                apiCall("get", "companies/get", true).then((response) => {
                    this.companies = response.data;
                });
            },
            updateDivision() {
                this.inuse = true;
                this.loading = true;
                const params = {...this.editedItem, badges: this.selectedBadges};
                this.selectedNewBadges.length> 0 && (params.newBadges = this.selectedNewBadges);
                apiCall(
                    "post",
                    "divisions/update",
                    {
                        data: params,
                    },
                    true
                ).then(() => {
                    this.getDataFromApi();
                    this.inuse = false;
                }).catch(() => {
                    this.inuse = false;
                });
            },
            createDivision() {
                this.inuse = true;
                this.loading = true;
                const params = {...this.editedItem, badges: this.selectedBadges};

                apiCall(
                    "post",
                    "divisions/create",
                    {
                        data: params,
                    },
                    true
                ).then(() => {
                    this.inuse = false;
                    this.getDataFromApi();
                })
                    .catch(() => {
                        this.inuse = false;
                    });
            },

            deleteDivision() {
                this.inuse = true;
                this.loading = true;
                const params = this.editedItem;

                apiCall(
                    "post",
                    "divisions/delete",
                    {
                        data: params,
                    },
                    true
                ).then((response) => {
                    this.inuse = false;
                    if (response?.data?.status) {
                        this.setSnackbar({
                            color: "success",
                            text: this.$t('division_deleted'),
                            toggle: true,
                        });

                        if (this.divisions.length == 1 && this.page != 1) {
                            this.page = this.page - 1;
                        }
                        this.filteredcompany = 0;

                        this.getDataFromApi();

                        this.closeDelete();
                    } else {
                        this.inuse = false;
                        this.loading = false;
                        this.setSnackbar({
                            color: "error",
                            text: response.data.result,
                            toggle: true,
                        });
                    }
                });
            },
            editItem(item) {
                this.editedIndex = item.id;
                this.editedItem.companyid = item.companyid;
                this.selectedCompany = item.companyid;
                this.editedItem = Object.assign({}, item);
                this.selectedBadges = item.badges
                    ? item.badges
                        .filter((elem) => elem.isselected === true)
                        .map((elem) => elem.id)
                    : [];
                this.dialog = true;
            },

            deleteItem(item) {
                this.editedIndex = item.id;
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },

            deleteItemConfirm() {
                // api remove
                this.deleteDivision();
            },
            close() {
                this.dialog = false;
                this.restoreData();
            },

            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },

            save() {
                if (this.editedIndex > -1) {
                    // edit
                    this.editedItem.id = this.editedIndex;
                    this.defaultItem.id = this.editedIndex;

                    this.defaultItem.companyid = this.selectedCompany;
                    this.editedItem.companyid = this.selectedCompany;

                    this.updateDivision();
                } else {
                    // create
                    this.defaultItem.companyid = this.selectedCompany;
                    this.editedItem.companyid = this.selectedCompany;
                    this.createDivision();
                }
                this.close();
            },
            setSearch() {
                this.searchValue = this.search;
                this.options = {...this.options, page: 1}
            },
            isDefaultBadgeAlreadySelected(id) {
                return this.editedItem.badges.filter(e => e.originalId === id).length > 0;
            }
        },
    };
</script>
<style scoped>
    @media (max-width: 1280px) {
        .flex-columned-toolbar >>> .v-toolbar__content {
            flex-wrap: wrap;
            justify-content: right;
        }

    }
    @media (max-width: 480px) {
        .divisions-table /deep/ .v-data-footer {
            justify-content: center;
            padding-bottom: 8px;
        }

        .divisions-table /deep/ .v-data-footer__pagination {
            display: none;
        }

        .divisions-table /deep/ .v-data-footer__select .v-input {
            margin: 0 10px !important;
        }
    }
</style>
