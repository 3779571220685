<template>
<v-layout>
<div class="col-12">
  <v-toolbar flat>
    <v-toolbar-title>{{ $t('notifications_center') }}</v-toolbar-title>
    <template v-slot:extension>
      <v-tabs
          v-model="tab"
          align-with-title
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>{{ $t('send_notification_to_user') }}</v-tab>
        <v-tab>{{ $t('notifications_history') }}</v-tab>
      </v-tabs>
    </template>
  </v-toolbar>
  <v-tabs-items v-model="tab">
    <v-tab-item>
      <v-card>
        <notifications v-if="tab==0"/>
      </v-card>
    </v-tab-item>
    <v-tab-item>
      <v-card>
        <notificationshistory v-if="tab==1"/>
      </v-card>
    </v-tab-item>
  </v-tabs-items>
</div>
</v-layout>
</template>
<script>
import NotificationsCenter from "@/views/NotificationsCenter.vue";
import Notificationshistory from "@/views/Notificationshistory.vue";
export default {
  components: {
    notifications: NotificationsCenter,
    notificationshistory: Notificationshistory,

  },
  name: "SANotificationsWrapper",
  data: () => ({
    loading: false,
    tab: null,
  })
}
</script>